<template>
  <gallery
    :images="images"
    :index="photoIndex"
    @close="$emit('close')"
  ></gallery>
</template>

<script>
import gallery from "vue-gallery";

export default {
  components: {
    gallery
  },
  props: {
    images: {
      type: Array,
      default() {
        return [];
      }
    },
    photoIndex: {
      type: Number,
      default: null
    }
  }
};
</script>
