export const videos: string[] = [
  "sB1A9CKKKHI",
  "lLP_gbvwAIQ",
  "Jz0gr3Q-qDk",
  "k2LZ_1kGUCo",
  "C426m0BzsvI",
  "5pmIGBe7f98",
  "4MKt_52_QAc",
  "ybfn-OIQ5yc",
  "3EsryAPJ3u8",
  "quBDiPz8dL4",
  "KT8iRKTU9pU",
  "9ssAhYtqcrA",
  "1OqHMX4fOA4",
  "rApVGyApw4w",
  "lS0Ux4TPOh8",
];
