



















import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class VideoTeaser extends Vue {
  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1 ||
      navigator.userAgent.indexOf("Tablet") !== -1
    );
  }
}
