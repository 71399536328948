import axios from "axios";
import { Email } from "@/types/Email";

const baseUrl = process.env.VUE_APP_EMAIL_API

export const postEmail = async (data: Email) => {
  try {
    axios.post(baseUrl, data);
  } catch (error) {
    throw new Error(error as any);
  }
};
