

















import Vue from "vue";
// @ts-ignore
import CookieLaw from "vue-cookie-law";
import Component from "vue-class-component";
import FooterBar from "@/layout/FooterBar.vue";
import NavBar from "@/layout/NavBar.vue";

@Component({
  components: {
    FooterBar,
    NavBar,
    CookieLaw,
  },
})
export default class App extends Vue {}
