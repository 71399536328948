import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import NotFound from "@/views/NotFound.vue";
import Galleria from "@/views/Galleria.vue";
import Servizi from "@/views/Servizi.vue";
import Contattaci from "@/views/Contattaci.vue";
import Privacy from "@/views/Privacy.vue"

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/galleria",
      name: "galleria",
      component: Galleria
    },
    {
      path: "/servizi",
      name: "servizi",
      component: Servizi
    },
    {
      path: "/contattaci",
      name: "contattaci",
      component: Contattaci
    },
    {
      path: "/privacy",
      name: "privacy",
      component: Privacy
    },
    {
      path: "/*",
      name: "not-found",
      component: NotFound,
      meta: {
        hideHeaderAndFooter: true
      }
    }
  ]
});
