import { Rating } from "@/types/Rating";

export const ratings: Rating[] = [
  {
    title: "Matrimonio perfetto",
    text:
      "Ragazzi professionali e disponibili. Nicola, Simona, Giancarlo, Pietro e Alessio: grazie di tutto! L’animazione e la musica live sono davvero il miglior connubio per una festa perfetta!",
    value: 5,
    author: "Dani El Meglio"
  },
  {
    title: "Grande festa!",
    text:
      "Siamo felici di aver contattato Nicola che con tutta la band ha soddisfatto ogni nostra esigenza! Sono stati disponibili e hanno esaudito ogni nostra richiesta, riuscendo a creare l'atmosfera perfetta! Una menzione particolare alla sezione dedicata ai Queen, apprezzatissima e realizzata con gusto raffinato! Consigliatissimi!",
    value: 5,
    author: ""
  },
  {
    title: "Giornata indimenticabile",
    text:
      "La perfetta colonna sonora. Una band bravissima con una voce bellissima.L’esperienza e la professionalità sono il loro biglietto da visita. Ogni nostra richiesta è stata accontentata e ogni invitato ha gradito.",
    value: 5,
    author: "Cinzia De Paola"
  },
  {
    title: "Una vera festa",
    text:
      "Un sound magico ed energico ma anche dolce e raffinato, garantito dalla versatilità del gruppo. Veri professionisti del fare festa, Nicola e la sua band hanno dimostrato fin da subito la loro disponibilità a personalizzare la loro scaletta per esaudire le nostre richieste affinché il giorno più importante della nostra vita fosse come lo avevamo sempre desiderato.La loro capacità di coinvolgere con discrezione ha garantito il divertimento di tutti! Grazie ragazzi!",
    value: 4.4,
    author: "Roberta Perrella"
  },
  {
    title: "Ottima scelta",
    text:
      "Giornata stupenda in loro compagnia... Professionali, allegri e coinvolgenti!",
    value: 5,
    author: "Alessandro Mancini"
  },
  {
    title: "Ottima scelta",
    text:
      "Consigliatissimi! La band, coinvolgente e divertente, ha piacevolmente animato la nostra festa di matrimonio suonando ottima musica.I componenti, tutti molto simpatici, si sono mostrati, fin dal primo contatto telefonico,molto disponibili ed hanno accolto con gentilezza le nostre richieste.",
    value: 4.2,
    author: "Luigi"
  },
  {
    title: "Nozze 6/07/2018",
    text:
      "Gruppo fantastico! Lo raccomando! Professionali e divertenti gruppo ben amalgamato, bel assortito. Sicuramente posso garantire la loro bravura e la loro serietà. Hanno animato la festa accontentando tutti e persino persone che non avevano gran attitudine al ballo... Si sono scatenate.",
    value: 5,
    author: "Daniela Saldarelli"
  },
  {
    title: "Consigliato per chi cerca musica dal vivo",
    text:
      "Abbiamo scelto la composizione di sassofono, basso, voce femminile e violino. Decisamente la scelta giusta per noi. Hanno accompagnato tutto il ricevimento con ottima musica, senza disturbare gli ospiti e soddisfacendo le nostre richieste. Consigliato a chi cerca solo la piacevole compagnia della musica.",
    value: 4,
    author: "Marzia Pellicciotti"
  },
  {
    title: "Ottima scelta!",
    text:
      "La musica è un ingrediente fondamentale per la riuscita di una buona festa e posso affermare che quella suonata da loro al nostro matrimonio è stata ottima. Consigliamo vivamente Nicola e la sua band per la loro professionalità, la disponibilità, la cortesia e la grande passione per ciò che fanno. È stato tutto stupendo e parte del merito è anche loro. Grazie! Seba e Anto",
    value: 4.6,
    author: "Sebastiano Budano"
  },
  {
    title: "",
    text:
      "Consigliatissimi! Eccezionali! Persone molto disponibili e professionali! Hanno esaudito ogni nostro desiderio!Non potevamo scegliere di meglio!Musica elegante ma allora stesso tempo divertente nelle giuste dosi , senza mai abbandonare l'eleganza!Grazie anche a voi per aver realizzato il nostro matrimonio che abbiamo sempre sognato!Con affetto Massimo & Marlì",
    value: 5,
    author: "Marlì Rocchi"
  },
  {
    title: "",
    text:
      "Sono stati eccezzionali, sono riusciti a mattere a proprio agio tutti gli invitati suonando splendida musica di compagnia per l'intera giornata ma senza essere invadenti, che era esattamente quello che avevamo chiesto. Abbiamo lasciato gestire a loro l'intera giornata e il risultato è stato stupendo. Professionisti esemplari! Potete affidarvi a loro e stare completamente tranquilli!Bravi, bravi, bravi!",
    value: 5,
    author: "Fabio D'emilio"
  },
  {
    title: "",
    text:
      "Il gruppo Incontri Sonori è stato davvero la scelta giusta per il nostro matrimonio. La professionalità e la bravura di ogni singolo componente del gruppo ha regalato attimi magici in ogni momento del ricevimento. Hanno accompagnato magistralmente il pranzo con canzoni e musica di sottofondo, toccando numerosi generi diversi e rivisitando molti brani classici in chiave Jazz o Bossa Nova. Oltre al talento dimostrato nell'accompagnamento, Incontri Sonori ci ha regalato divertenti momenti di ballo e discoteca. Non solo, nei nostri incontri precedenti all'evento, ci hanno saputo dare numerosi consigli legati alla musica. Ad esempio, ci hanno consigliato di integrare un violinista durante la celebrazione del Matrimonio e durante il buffet di antipasti, e anche questa è stata un'ottima scelta! Noi non possiamo fare altro che ringraziare e consigliare Incontri Sonori, con la garanzia di uno straordinario successo!",
    value: 5,
    author: "Luca Todaro"
  },
  {
    title: "",
    text:
      "Grandissimi musicisti..!! Consigliati a tutti..!! Ottimo prezzo e disponibilita illimitata. Sono in grado di esaudire ogni vostra richiesta! Pianoforte, sax, tromba e una fantastica voce femminile vi accompagnerá per tutto il giorno con ogni genere di musica e anche qualche gioco di animazione! Ottima scelta!",
    value: 5,
    author: "Basso"
  }
];
