<template>
  <v-container>
    <!-- <v-overlay :value="loaderValue">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>-->
    <!-- Slider with the youtube videos -->
    <vueper-slides
      :fixedHeight="isMobile ? '200px' : '400px'"
      class="no-shadow my-12"
      bullets-outside
      transition-speed="250"
    >
      <template v-slot:arrow-left>
        <v-icon :color="isMobile ? 'white' : 'black'" x-large>fas fa-chevron-left</v-icon>
      </template>
      <template v-slot:arrow-right>
        <v-icon :color="isMobile ? 'white' : 'black'" x-large>fas fa-chevron-right</v-icon>
      </template>
      <vueper-slide v-for="(video, i) in videos" :key="i">
        <template v-slot:content>
          <div class="d-md-flex justify-md-center">
            <youtube
              :video-id="video"
              :player-width="isMobile ? '100%' : undefined"
              :player-height="isMobile ? '200px' : '400px'"
            ></youtube>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>

    <!-- Gallery of photos -->
    <gallery :images="photos" :index="photoIndex" @close="photoIndex = null"></gallery>
    <div class="gallery">
      <div
        @click="photoIndex = index"
        class="gallery-panel cursor-pointer"
        v-for="(photo, index) in renderPhotos"
        :key="index"
      >
        <v-hover v-slot:default="{ hover }">
          <transition name="fade">
            <v-img v-if="photo" :lazy-src="photo" :src="photo" :class="{ 'on-hover': hover }" />
          </transition>
        </v-hover>
      </div>
    </div>
    <div class="d-flex justify-center mb-12">
      <v-btn
        v-if="indexPhotos < photos.length"
        @click="showMore()"
        outlined
        :ripple="false"
        dark
        large
        color="primary"
      >Altre immagini</v-btn>
    </div>
  </v-container>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import gallery from "vue-gallery";
import { photos } from "../data/photo";
import { videos } from "../data/video";
import { scrollTo } from "vue-scrollto";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Gallery",
  components: {
    gallery,
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      photoIndex: null,
      photos,
      videos,
      indexPhotos: 12,
      loaderValue: true
    };
  },
  computed: {
    isMobile() {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    },
    renderPhotos() {
      return this.photos.slice(0, this.indexPhotos);
    }
  },
  beforeDestroy() {
    this.loaderValue = false;
  },

  mounted() {
    scrollTo("body");
    setInterval(() => {
      this.loaderValue = false;
    }, 1000);
  },
  methods: {
    showMore() {
      this.indexPhotos += 20;
    },
    generateVideoUrl(id) {
      return `https://www.youtube.com/embed/${id}"`;
    }
  }
};
</script>

<style scoped lang="scss">
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 1rem;
  margin: 5rem auto;
}
.gallery-panel .v-image {
  width: 100%;
  height: 50vw;
  object-fit: cover;
  border-radius: 0.75rem;

  @media only screen and (min-width: 768px) {
    height: 22vw;
  }
}

.v-image.on-hover {
  opacity: 0.5;
}
</style>
