export const photos: string[] = [
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1661113874/incontri-sonori/galleria/IMG_6946_xl3rqi.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1661113874/incontri-sonori/galleria/IMG_6942_cnbgsv.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1661113874/incontri-sonori/galleria/IMG_6941_w7gf4t.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1661113874/incontri-sonori/galleria/B3494355-D1EF-4B10-84DA-981692B7067F_rinnx7.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1617907914/incontri-sonori/galleria/WhatsApp_Image_2021-04-08_at_12.41.29_1_fy7rij.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1617907914/incontri-sonori/galleria/WhatsApp_Image_2021-04-08_at_12.41.28_cantkt.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709835/incontri-sonori/galleria/38_viqbp8.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709833/incontri-sonori/galleria/43_sdgmo7.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709833/incontri-sonori/galleria/44_rrx4yu.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709831/incontri-sonori/galleria/42_gbvb4z.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709829/incontri-sonori/galleria/45_ljtb4n.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709828/incontri-sonori/galleria/39_ngfkah.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709828/incontri-sonori/galleria/41_o3ers3.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709828/incontri-sonori/galleria/40_jn3m9y.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1617907914/incontri-sonori/galleria/WhatsApp_Image_2021-04-08_at_12.41.29_oq3fqj.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709821/incontri-sonori/galleria/24_yig6mt.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709821/incontri-sonori/galleria/23_fex4sf.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709820/incontri-sonori/galleria/22_z1yc7l.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709818/incontri-sonori/galleria/21_soifuf.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709818/incontri-sonori/galleria/36_eq0ue4.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709817/incontri-sonori/galleria/25_vopwaa.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709817/incontri-sonori/galleria/33_oxsis5.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709817/incontri-sonori/galleria/34_kgt4kf.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709817/incontri-sonori/galleria/35_uhkrfc.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709816/incontri-sonori/galleria/20_tglnjj.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709816/incontri-sonori/galleria/19_sook5q.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709816/incontri-sonori/galleria/14_rkstpm.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709816/incontri-sonori/galleria/15_ic41is.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709814/incontri-sonori/galleria/13_uickot.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709813/incontri-sonori/galleria/18_hsudde.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709812/incontri-sonori/galleria/1_lp6hvf.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709812/incontri-sonori/galleria/17_ypsbji.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709812/incontri-sonori/galleria/12_nutem0.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709811/incontri-sonori/galleria/16_mx8wqw.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709810/incontri-sonori/galleria/11_ypsnzw.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709810/incontri-sonori/galleria/10_zafu4p.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709810/incontri-sonori/galleria/9_iucsfa.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709809/incontri-sonori/galleria/8_fidstg.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709808/incontri-sonori/galleria/7_bnoves.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709807/incontri-sonori/galleria/6_y6bkko.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709806/incontri-sonori/galleria/32_nx6nby.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709806/incontri-sonori/galleria/4_mv1snb.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709805/incontri-sonori/galleria/5_fawpbe.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709804/incontri-sonori/galleria/31_lkosv0.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709804/incontri-sonori/galleria/3_rwhvvy.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709804/incontri-sonori/galleria/29_unbyra.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709803/incontri-sonori/galleria/30_x5egsc.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709803/incontri-sonori/galleria/2_takdwf.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709803/incontri-sonori/galleria/27_fx20wz.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709803/incontri-sonori/galleria/28_rs4ftc.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709802/incontri-sonori/galleria/0_nzxqmm.jpg",
  "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1576709802/incontri-sonori/galleria/26_ukcgli.jpg"
];
