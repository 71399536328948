



































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class IntroText extends Vue {
  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }
}
