












































import Vue from "vue";
import Component from "vue-class-component";
import ImagesGrid from "@/components/ImagesGrid.vue";
import ScopriServiziTeaser from "@/components/ScopriServiziTeaser.vue";
import VideoTeaser from "@/components/VideoTeaser.vue";
import IntroText from "@/components/IntroText.vue";

@Component({
  components: {
    ImagesGrid,
    ScopriServiziTeaser,
    VideoTeaser,
    IntroText,
  },
})
export default class Home extends Vue {
  private loaderValue: boolean = true;

  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  get containerMaxHeight() {
    const width = window?.innerWidth;
    if (width && width > 1440) {
      return 900;
    }
    return 750;
  }

  get imageMaxHeight() {
    const width = window?.innerWidth;
    if (width && width > 1440) {
      return 1100;
    }
    return 840;
  }

  private beforeDestroy() {
    this.loaderValue = false;
  }

  private mounted() {
    setInterval(() => {
      this.loaderValue = false;
    }, 1000);
  }
}
