





































































































import Vue from "vue";
import Component from "vue-class-component";
import DropDownMenu from "./DropDownMenu.vue";

@Component({
  components: {
    DropDownMenu
  }
})
export default class NavBar extends Vue {
  private showMenu: boolean = false;

  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  private toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
