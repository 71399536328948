































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { required, email } from "vuelidate/lib/validators";
import { Validation, validationMixin } from "vuelidate";
import { postEmail } from "@/api/email";
import scrollTo from "vue-scrollto";
import { Email } from "@/types/Email";

@Component({
  mixins: [validationMixin],
  validations: {
    name: { required },
    email: { required, email },
    eventType: { required },
    requestDetails: { required },
    privacy: { sameAs: (val) => val === true },
  },
})
export default class Contattaci extends Vue {
  private name: string = "";
  private email: string = "";
  private eventType: string = "";
  private requestDetails: string = "";
  private privacy: boolean = false;
  private phoneNumber: string = "";
  private showSuccessMessage: boolean = false;
  private isSendingEmail: boolean = false;
  private loaderValue: boolean = true;

  private beforeDestroy() {
    this.loaderValue = false;
  }

  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  get requestDetailsErrors() {
    const errors: string[] = [];
    if (!this.$v?.requestDetails?.$dirty) return errors;
    !this.$v.requestDetails.required &&
      errors.push("Non può essere lasciato in bianco");
    return errors;
  }

  get privacyErrors() {
    const errors: string[] = [];
    if (!this.$v?.privacy?.$dirty) return errors;
    !this.$v.privacy.sameAs && errors.push("Campo necessario");
    return errors;
  }

  get eventTypeErrors() {
    const errors: string[] = [];
    if (!this.$v?.eventType?.$dirty) return errors;
    !this.$v.eventType.required && errors.push("Inserisci il tipo di evento");
    return errors;
  }
  get nameErrors() {
    const errors: string[] = [];
    if (!this.$v?.name?.$dirty) return errors;
    !this.$v.name.required && errors.push("Inserisci il nome");
    return errors;
  }
  get emailErrors() {
    const errors: string[] = [];
    if (!this.$v?.email?.$dirty) return errors;
    !this.$v.email.email && errors.push("Formato email incorretto");
    !this.$v.email.required && errors.push("Inserisci la email");
    return errors;
  }

  private mounted() {
    scrollTo.scrollTo("body");
    setInterval(() => {
      this.loaderValue = false;
    }, 1000);
  }

  private submit() {
    this.$v?.$touch();
    if (this.$v?.$invalid) {
      return;
    }
    const data: Email = {
      templateId: process.env.VUE_APP_EMAIL_TEMPLATE,
      to: process.env.VUE_APP_EMAIL_TO,
      replyTo: this.email,
      data: {
        email: this.email,
        name: this.name,
        phoneNumber: this.phoneNumber,
        eventType: this.eventType,
        requestDetails: this.requestDetails,
      },
    };

    this.showSuccessMessage = true;
    this.clear();
    scrollTo.scrollTo("body");
    postEmail(data);
  }

  private clear() {
    this.$v?.$reset();
    this.name = "";
    this.email = "";
    this.phoneNumber = "";
    this.requestDetails = "";
    this.eventType = "";
    this.privacy = false;
  }
}
