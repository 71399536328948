import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import LazyYoutubeVideo from "vue-lazy-youtube-video";
import VueYouTubeEmbed from "vue-youtube-embed";

import "vue-lazy-youtube-video/dist/style.css";

Vue.config.productionTip = false;

// @ts-ignore
Vue.component("LazyYoutubeVideo", LazyYoutubeVideo);
Vue.use(VueYouTubeEmbed);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
