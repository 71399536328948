



















































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import scrollTo from "vue-scrollto";
import { ratings } from "@/data/ratings";
import { Rating } from "@/types/Rating";

@Component
export default class Servizi extends Vue {
  private ratings: Rating[] = ratings;
  private loaderValue: boolean = true;

  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  private mounted() {
    scrollTo.scrollTo("body");
    setInterval(() => {
      this.loaderValue = false;
    }, 1000);
  }
}
