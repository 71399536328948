





















































import Vue from "vue";
import Component from "vue-class-component";
import scrollTo from "vue-scrollto/vue-scrollto";
import { Icon } from "@/types/Icon";

@Component
export default class FooterBar extends Vue {
  private socials: Icon[] = [
    {
      icon: "fab fa-facebook",
      url: "https://www.facebook.com/musica.eventi.matrimoni"
    },
    {
      icon: "fab fa-youtube",
      url: "https://www.youtube.com/channel/UCiaWPP003DfWCQhr9qet31g"
    },
    {
      icon: "fab fa-instagram",
      url: "https://www.facebook.com/musica.eventi.matrimoni"
    }
  ];

  private scrollTop() {
    scrollTo.scrollTo("body");
  }
}
