





























































import Vue from "vue";
import Component from "vue-class-component";
import Carousel from "./Carousel.vue";
import { photos } from "@/data/photo";

@Component({
  components: {
    Carousel
  }
})
export default class ImagesGrid extends Vue {
  private showImage: boolean = false;
  private photoIndex: number | null = null;

  get isMobile() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }
  get images() {
    return this.isMobile ? photos.slice(0, 4) : photos.slice(0, 8);
  }
}
